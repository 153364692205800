import React, { Component } from "react";
//import ReactDOM from 'react-dom';
import Logo from './../img/Logo_Origo_Transp_TexteClair_ras_3_5x1_1.png';
import SeparatorSection from './SeparatorSection';


export class Navigation extends Component {
    render() {
      return (
        <nav id="menu" className="navbar navbar-default navbar-fixed-top origo-navbar">
          <SeparatorSection size={4} color={"#ffa000"}/>
          <div className="container origo-navigation">
            <div className="navbar-header">
              <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1">
                {" "}
                <span className="sr-only">Toggle navigation</span>{" "}
                <span className="icon-bar"></span>{" "}
                <span className="icon-bar"></span>{" "}
                <span className="icon-bar"></span>{" "}
              </button>
              <a className="navbar-brand page-scroll" href="#page-top">
                <img src={Logo} className="origo-logo" alt="ORIGO"/>
              </a>{" "}
            </div>
  
            <div className="collapse navbar-collapse navbar-right">
              <p className="origo-main-title">{this.props.data ? this.props.data.main_title : "Origo"}</p>
            </div>

           <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
              <ul className="nav navbar-nav navbar-right origo-menu-items">
              <li>
                  <a href="/#page-top" className="page-scroll">
                    Accueil
                  </a>
                </li>
                <li>
                  <a href="/#sectionServices" className="page-scroll">
                    Nos services
                  </a>
                </li>
                <li>
                  <a href="/#sectionRelationship" className="page-scroll">
                    Notre approche
                  </a>
                </li>
                <li>
                  <a href="/#sectionFeatures" className="page-scroll">
                    Pourquoi nous
                  </a>
                </li>
                <li>
                  <a href="/#sectionContact" className="page-scroll">
                    Contactez-nous
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      );
    }
  }
  
  export default Navigation;
  