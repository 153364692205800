import React, { Component } from "react";
import TitleLine from "../components/TitleLine"
import ImgFeatureTeam from './../img/Mosaique_10.png';

class ModalFeatureTeam extends Component {
    
    render() { 
        return (  
            <div className="modal fade" id="Modal_FeatureTeam" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">

                        <TitleLine data="Une équipe expérimentée" />
                        <div className="row no-gutters">
                            <div className="col-md-2">
                                <img src={ImgFeatureTeam} alt="..." className="card-img origo-img-fluid" />
                            </div>
                            <div className="col-md-10">
                                <div className="card-body origo-detail-text-xl">
                                        <p><span className="origo-text-light-color">Origo</span> a la chance de pourvoir compter sur des collaborateurs, développeurs et responsables de projets, à la fois d'expériences issues de secteurs variés, et techniquement performants.</p>
                                        <p>De ces parcours en entreprise, dans la logistique, l'automobile et aéronautique, l'électronique et communications, la biologie et le médical, les services, nous pouvons compter sur un large éventail d'expériences et de technologies à la fois contemporaines et éprouvées.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-md-12">
                                <div className="card-body origo-detail-text-m">
                                    <p>Bien entendu, nous ne nous reposons pas sur nos acquis, et nous nous devons également d'être au fait des nouveaux outils et attentifs aux tendances. Nous les testons, et évaluons s'ils sont pertinents pour nous, c'est à dire adaptés à nos objectifs et nos méthodes, et surtout pour quels types de projets.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary origo-btn-dialog" data-dismiss="modal">Retour</button>
                    </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default ModalFeatureTeam;