import React, { Component } from 'react'
import ImgTeam from './../img/clockwork.png';


class SectionRelationship extends Component {

    
    render() { 
        return (  
            <div id="sectionRelationship">
                <div className="origo-about-bloc">
                    <div className="row">
                        <div className="col-xs-12 col-md-4"> <img src={ImgTeam} className="img-responsive" alt=""/> </div>
                        <div className="col-xs-12 col-md-8">
                        <div className="about-text">
                            <h2>Notre approche</h2>
                            <p>Notre objectif premier est votre satisfaction. Oui bien sûr, tout prestataire met cela en avant mais au delà de l'évidence, nous admettons que ce qui est bon pour vous l'est aussi pour nous...</p>
                            <h3>Objectivement...</h3>
                            <p>... nous sommes conscients que si nos clients sont satisfaits, cela représente un intérêt professionnel considérable pour nous, car cela induit :</p>

                            <div className="list-style">
                                <div className="col-lg-6 col-sm-6 col-xs-12">
                                    <ul>
                                    <li>un crédit d'estime chez nos clients, qui peuvent refaire appel à nous pour de nouveaux besoins</li>
                                    <li>une bonne réputation pour notre travail et les services <span className="origo-text-light-color">Origo</span></li>
                                    </ul>
                                </div>
                                <div className="col-lg-6 col-sm-6 col-xs-12">
                                    <ul>
                                    <li>un support client moindre car nos produits fonctionnent correctement et sont bien utilisés</li>
                                    <li>...et pour nous-même une satisfaction professionnelle plus que motivante</li>
                                    </ul>
                                </div>
                            </div>
                            <p>&nbsp;</p>
                            <h3>Une relation saine et cordiale</h3>
                            <p>Ainsi dans notre travail et nos prestations, tout cela se traduit par des engagements concrêts :</p>
                            <ul>
                                <li>une écoute basée sur l'humilité : c'est vous qui connaissez votre métier, ses processus et spécificités; de notre coté, nous devons comprendre et satisfaire le besoin, pour fournir <span className="origo-hlight-word">des solutions, pas des contraintes</span></li>
                                <li>un dialogue précis, didactique et courtois</li>
                                <li>pas de complexité technique volontaire dans le discours. On peut mener à bien un projet sans le polluer par un discours technique abscons, pour tout ce qui est spécifiquement de notre ressort</li>
                                <li>une parfaite clarté sur le résultat attendu, notre collaboration et le processus de développement, les conditions financières. <span className="origo-hlight-word">Pas de surprise à la livraison</span></li>
                                <li>des échanges réguliers, mais non superflus, dans toutes les phases du projet. Pas de "réunionite", mais des points d'avancements, brefs ou conséquents si nécessaires, récurrents ou à la demande. Nous nous adaptons à votre volonté de suivi</li>
                                <li>une capacité réelle d'<span className="origo-hlight-word">adaptation</span> en cours de projet. Même mûrement réfléchi, un projet peut rencontrer des écueils ou soulever des questions ou contraintes non prévues en cours de réalisation. Le bons-sens doit primer sur la rigidité pour solutionner ces points et satisfaire le besoin</li>
                            </ul>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default SectionRelationship;