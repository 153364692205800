import React, { Component } from "react";
import TitleLine from '../components/TitleLine';

export class PgLegal extends Component {
    render() {
        return (
            <div id="pgLegal">
                <div id="technos" className="container origo-page-container">
                    <TitleLine data="Mentions Légales" />

                    <p>Le site <span className="origo-text-light-color">Origo</span> (origo-pro.fr) est édité par la société :</p>

                    <p>
                        <h7>LRFS</h7> – SARL au capital de 1000 €<br/>
                        559 route de la Magdelaine 31380 Montjoire<br/>
                        Immatriculée au RCS de Toulouse sous le n° 888 737 418<br/>
                        N° de TVA Intracommunautaire : FR 38 888737418<br/>
                        Contact : (+33) 7 64 12 12 05<br/>
                        Courriel : contact@origo-pro.fr
                    </p>
                    <p>
                    Ce site est hébergé par : O2SWITCH<br/>
                    222 Boulevard Gustave Flaubert 63000 Clermont-Ferrand
                    </p>

                    <h6>Conditions générales d’utilisation</h6>

                    <h5>Article 1 : Informations Utilisateurs</h5>

                    Toute personne (Ci-après : utilisateur du Site) qui accède au présent site s’engage à prendre connaissance et respecter les présentes conditions générales d’utilisation. Il reconnaît en outre disposer de la compétence et des moyens nécessaires pour accéder et utiliser ce site. De plus, l’utilisateur du site s’engage à accéder au site en utilisant un matériel récent, ne contenant pas de virus et avec un navigateur de dernière génération mis-à-jour.

                    <h5>Article 2 : Cookies – Collectes de données</h5>

                    L’utilisateur du site est informé que lors de ses visites, un cookie peut s’installer automatiquement sur son logiciel de navigation. Un cookie est un élément qui ne permet pas d’identifier l’utilisateur mais sert à enregistrer des informations relatives à la navigation de celui-ci sur le site Internet, comme les préférences de navigation ou la récupération de données statistiques sur le trafic du site. L’utilisateur du  site  pourra désactiver ce cookie par l’intermédiaire des paramètres figurant au sein de son logiciel de navigation.

                    <h5>Article 3 : Limitations</h5>

                    <h7>3.1 – Pertinence des contenus</h7>

                    <p>
                        LRFS maintient les informations contenues sur ce site de façon aussi précise que possible et le remet à jour régulièrement. Cependant, il peut parfois contenir inexactitudes ou omissions. Si l’utilisateur du site constate une lacune, erreur ou ce qui parait être un dysfonctionnement, il peut le signaler par courriel à l’adresse de contact, en décrivant le problème de la manière la plus précise possible. Le directeur de publication s’efforcera de corriger les erreurs ou dysfonctionnements dans les meilleurs délais, sans que sa responsabilité puisse être recherchée et engagée.
                        En outre LRFS se réserve le droit de corriger et modifier à tout moment le contenu du Site sans que sa responsabilité puisse être engagée de ce fait, LRFS pouvant apporter à tout moment des améliorations et/ou changements au(x) produit(s) décrits sur ce Site.
                    </p>
                    <h7>3.2 – Limitations sur les contenus</h7>

                    <p>
                        Le présent Site peut offrir des liens vers d’autres sites ou ressources disponibles sur Internet. LRFS ne dispose d’aucun moyen pour contrôler les sites sur lesquels pointent ces liens. Il ne peut répondre, ni garantir de la disponibilité de tels sites et sources externes, ni ne la garantit. Il ne peut être tenu pour responsable de tout dommage, de quelque nature que ce soit, résultant du contenu de ces sites ou sources externes, et notamment des informations, produits ou services qu’ils proposent, ou de tout usage qui peut être fait de ces éléments. Les risques liés à cette utilisation incombent pleinement à l’utilisateur du site, qui doit se conformer à leurs conditions d’utilisation.
                        Tout contenu téléchargé se fait aux risques et périls de l’utilisateur du site et sous sa seule responsabilité. En conséquence, LRFS ne saurait être tenu responsable d’un quelconque dommage subi par l’ordinateur de l’utilisateur du site ou d’une quelconque perte de données consécutives au téléchargement.
                    </p>

                    <h5>Article 4 : Propriété intellectuelle</h5>

                    Tout le contenu du présent site incluant, de façon non limitative, les graphismes, images, textes, vidéos, animations, sons, logos, gifs et icônes ainsi que leur mise en forme sont la propriété exclusive de LRFS à l’exception des marques, logos ou contenus appartenant à d’autres sociétés partenaires ou auteurs qui sont leurs propriétés respectives, et utilisés avec leur accord.
                    Toute représentation totale ou partielle de ce site par quelque procédé que ce soit, sans l’autorisation expresse de LRFS est interdite et constituerait une contrefaçon sanctionnée par les article L 335-2 et suivants du Code de la propriété intellectuelle. Il faut rappeler que le non-respect de cette interdiction peut engager la responsabilité civile et pénale du contrefacteur.

                    <h5>Article 5 : Données personnelles</h5>

                    L’utilisateur du site dispose d’un droit d’accès, de modification, de rectification et de suppression des données qui le concernent. Pour l’exercer, l’utilisateur peut s’adresser auprès du directeur de la publication visé plus haut.

                    <h5>Article 6 : Droit applicable</h5>

                    Les présentes Conditions Générales sont soumises au droit interne français. La langue des Conditions Générales est la langue française. En cas de litige, les tribunaux dont dépend le siège de LRFS seront seuls compétents.


                </div>
            </div>
        )
    }
}

export default PgLegal;