import React, { Component } from "react";
//import Modal from "react-bootstrap/Modal"; 
import TitleLine from "../components/TitleLine"
import ImgWebSite from './../img/site_web.png';
import ImgEshop from './../img/boutique2.png';
import ImgClicC from './../img/click_collect.png';


class ModalWebsites extends Component {
    
    render() { 
        return (  
            <div className="modal fade" id="Modal_Websites" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">

                            <TitleLine data="Votre site vitrine sur Internet" />
                            <div className="row no-gutters">
                                <div className="col-md-2">
                                    <img src={ImgWebSite} alt="" className="card-img origo-img-fluid" />
                                </div>
                                <div className="col-md-10">
                                    <div className="card-body">
                                        <div className="origo-detail-text-xl">
                                            <p>Faites vous connaître de la meilleure façon grâce à une vitrine pour vos entreprises, marques ou organisations. Présentez vos services et produits, parlez de vos méthodes, de vos concepts, de vos valeurs.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row no-gutters">
                                <div className="col-md-12">
                                    <div className="card-body origo-detail-text-m">
                                        <p>A partir de vos indications, le site Internet que nous vous proposerons répondra aux exigences de <span className="origo-hlight-word">clarté et de convivialité</span> grâce à un design contemporain, de performances et surtout d'<span className="origo-hlight-word">attrait</span> pour ce que vous souhaitez mettre en avant.</p>
                                        <p>Selon votre souhait, votre site ne demandera aucune intervention de votre part, ou bien pourra être enrichi et renouvelé régulièrement par de nouveaux articles, pour votre communauté ou vos partenaires et clients. C'est votre choix.</p>
                                        <p>Si la rédaction des <span className="origo-hlight-word">textes du site</span> vous semble fastidieuse, nous pouvons nous en charger à partir de vos indications. Vous pourrez ainsi choisir parmi ceux que nous aurons rédigé pour vous, tels quel ou en les enrichissant.</p>
                                    </div>
                                </div>
                            </div>
                            <TitleLine data="Le commerce en ligne à votre portée" />
                            <div className="row no-gutters">
                                <div className="col-md-10">
                                    <div className="card-body">
                                        <div className="origo-detail-text-xl">
                                            <p>Que vous soyez à la tête d'un point de vente ou commerçant exclusivement en ligne, on ne présente plus les avantages de disposer d'un site e-commerce (zone de chalandise illimitée, pas de contrainte d'horaires ou de charge supplémentaire de locaux de vente, un catalogue produits aussi exhaustif que vous le souhaitez, une gestion du stock totalement au choix,…).</p>
                                            </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <img src={ImgEshop} alt="" className="card-img origo-img-fluid" />
                                </div>
                            </div>

                            <div className="row no-gutters">
                                <div className="col-md-12">
                                    <div className="card-body origo-detail-text-m">
                                            <p>Avec un visuel personnalisé comme il vous convient selon l'<span className="origo-hlight-word">identité de votre entreprise ou de votre marque</span>, nous proposons des solutions correspondant à tous les besoins courants en la matière. Ainsi vous disposez en permanence de l'état de vos commandes en temps réel, vous gérez toutes les <span className="origo-hlight-word">spécificités de produits</span>, quelles que soient leurs complexités (à l'unité, en lots, par unités de mesures, avec DLUO ou avec des déclinaisons comme la taille, la couleur,…), votre boutique peut adapter son contenu en fonction de votre stock, et vous pouvez être directement alertés des évènements concernant l'activité de votre boutique.</p>
                                            <p>Que vous stockiez vos produits, commandez en flux tendu ou pratiquiez du drop-shipping, nous vous proposons des solutions pour chaque cas de figure et bien entendu la possibilité de les combiner. A cela nous pouvons adjoindre toutes fonctions spécifiques liée à votre activité de vente que vous souhaiteriez.</p>
                                            <p>Vous pouvez choisir tous les <span className="origo-hlight-word">modes de paiement</span> disponibles. Si vous le souhaitez, nous vous proposons notre expérience pour vous aider dans le choix des prestataires de paiement en ligne. </p>
                                            <p>Pour acheminer vos commandes, tous les <span className="origo-hlight-word">modes de transport</span> vous sont accessibles : services classiques (Colissimo, Chronopost), points-relais (MondialRelay, Colissimo relais), transporteurs (UPS, DHL, …). Nous pouvons intégrer dans votre boutique la gestion des colis pour les transporteurs que vous aurez choisis.</p>
                                    </div> 
                                </div>
                            </div>

                            <TitleLine data="Clic & Collect" />
                            <div className="row no-gutters">
                                <div className="col-md-2">
                                    <img src={ImgClicC} alt="" className="card-img origo-img-fluid" />
                                </div>
                                <div className="col-md-10">
                                    <div className="card-body">
                                        <div className="origo-detail-text-xl">
                                        <p>Pour que votre clientèle puisse bénéficier de vos produits et services sans parcourir votre point de vente, le Clic & Collect est la solution qui vous permet de recueillir leur commandes, préparer les produits, en attente de retrait. Vous pourrez également dialoguer avec vos clients si besoin.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row no-gutters">
                                <div className="col-md-12">
                                    <div className="card-body origo-detail-text-m">
                                            <p>Pour cela, nous pouvons vous proposer un ensemble d'outils couvrant tous les besoins liés au Clic & Collect, du plus simple d'emploi au plus riche de fonctionnalités.</p>
                                            <p>Disposer d'une boutique en ligne n'est pas une obligation. Nous vous proposons des <span className="origo-hlight-word">solutions de Clic & Collect autonomes</span>, simples et efficaces.</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary origo-btn-dialog" data-dismiss="modal">Retour</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default ModalWebsites;