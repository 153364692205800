import React, { Component } from "react";

export class SectionContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      message: ''
    }
  }
  
  onNameChange(event) {
    this.setState({name: event.target.value});
  }

  onEmailChange(event) {
    this.setState({email: event.target.value})
  }

  onMessageChange(event) {
    this.setState({message: event.target.value})
  }

  resetForm(){
    this.setState({name: '', email: '', message: ''});
  }

  checkAllFieldsValid() {
    let name = document.getElementById("name");
    let email = document.getElementById("email");
    let message = document.getElementById("message");
    return name.validity.valid && email.validity.valid && message.validity.valid;
  }

  /* Cette fonction doit être rapprochée de public/js/contact_me.js */
  handleSubmit(e) {

    e.preventDefault();

    if (!this.checkAllFieldsValid()) return;

    fetch(this.props.data ? this.props.data.backend : "", {
      method: "POST",
      body: JSON.stringify(this.state),
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
      },
    }).then(
        (response) => (response.json())
          ).then((response)=> {
        if (response.status === 'success') {
          alert("Message envoyé"); 
          this.resetForm()
        } else if(response.status === 'fail') {
          alert("Erreur d'envoi du message...")
        }
      }
    )
  }
  
  render() {
    return (
      <div>
        <div id="sectionContact">
          <div className="container">
            <div className="col-md-8">
              <div className="row">
                <div className="section-title">
                  <h2>{this.props.data ? this.props.data.title : "Contact"}</h2>
                  <p>
                    {this.props.data ? this.props.data.subtitle : "Please fill out the form below to send us an email and we will get back to you as soon as possible."}
                  </p>
                </div>
                <form name="sentMessage" id="contactForm" noValidate method="POST" >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          id="name"
                          className="form-control"
                          placeholder={this.props.data ? this.props.data.fieldName : "Name"}
                          required="required"
                          onChange={this.onNameChange.bind(this)} 
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="email"
                          id="email"
                          className="form-control"
                          placeholder={this.props.data ? this.props.data.fieldEmail : "Email"} 
                          required="required"
                          onChange={this.onEmailChange.bind(this)}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <textarea
                      name="message"
                      id="message"
                      className="form-control"
                      rows="4"
                      placeholder={this.props.data ? this.props.data.fieldMessage : "Message"}
                      required
                      onChange={this.onMessageChange.bind(this)}
                    ></textarea>
                    <p className="help-block text-danger"></p>
                  </div>
                  <div id="success"></div>
                  <button type="submit" className="btn btn-custom btn-lg">
                    {this.props.data ? this.props.data.buttonSend : "Send Message"}
                  </button>
                </form>
              </div>
            </div>
            <div className="col-md-3 col-md-offset-1 contact-info">
              <div className="contact-item">
                <h3>{this.props.data ? this.props.data.labelContact : "Contact Info"}</h3>
                <p>
                  <span>
                    <i className="fa fa-phone"></i> {this.props.data ? this.props.data.labelPhone : "Phone"}
                  </span>{" "}
                  {this.props.data ? this.props.data.phone : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i> {this.props.data ? this.props.data.labelEmail : "Email"}
                  </span>{" "}
                  {this.props.data ? this.props.data.email : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-map-marker"></i> {this.props.data ? this.props.data.labelAddress : "Address"}
                  </span>
                  {this.props.data ? this.props.data.address.map((d) => (<p className="origo-comment">{d}</p>)):""}
                </p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row">
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SectionContact;
