import React, { Component } from "react";

export class TitleLine extends Component {
    render() {
        return (
            <div>
                <div className="origo-content__textSeparator">
                    <span className="origo-line__separator"></span>
                    <p className="origo-text__separator">{this.props.data ? this.props.data : "loading..."}</p>
                    <span className="origo-line__separator"></span>
                </div>

                <div className="origo-title-margin"></div>
            </div>
        )
    }
}

export default TitleLine;