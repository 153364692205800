import React, { Component } from "react";
import ModalEmpty from '../modals/ModalEmpty';
import ModalLittleApps from '../modals/ModalLittleApps';
import ModalMultiApps from '../modals/ModalMultiApps';
import ModalWebsites from '../modals/ModalWebsites';
import ModalUpdtApps from '../modals/ModalUpdtApps';

export class SectionServices extends Component {

  displayDetails(section) {
      switch(section) {
        case "#Modal_LittleApps": return <ModalLittleApps />; 
        case "#Modal_MultiApps": return <ModalMultiApps />;
        case "#Modal_Websites": return <ModalWebsites />; 
        case "#Modal_UpdtApps": return <ModalUpdtApps />;
        case "#Modal_Exchanges":
        case "#Modal_ProtoPoc": 
        default: return <ModalEmpty />;
      }
  };

  render() {
    return (
      <div id="sectionServices" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>{this.props.data ? this.props.data.title : ""}</h2>
            {this.props.data ? this.props.data.subtitles.map((d, i) => (
              <h4 key={`st-${i}`} >{d}</h4>
            )) : ""}
          </div>
          <div className="row">
            {this.props.data ? this.props.data.servicesList.map((d, i) => ( 
                <div key={`p-${i}`} className="card-box col-xs-12 col-md-3">
                  <div className="card">
                    {" "}
                    <i className={d.icon}></i>
                    <div className="card-body">
                      <h5 className="card-title">{d.name}</h5>
                      <p className="card-text">{d.text}</p>
                        <button type="button" className="btn btn-more btn-sm" data-toggle="modal"  data-target={d.target}>
                          {this.props.data ? this.props.data.buttonMore : "More..."}
                        </button>

                        {this.displayDetails(d.target)}
                        
                    </div>
                  </div>
                </div>
              ))
              : "loading"}
          </div>
        </div>
      </div>
    );
  }
}

export default SectionServices;
