import React, { Component } from "react";
import TitleLine from "../components/TitleLine"
import ImgFeatureMethods from './../img/Scrum.png';

class ModalFeatureMethods extends Component {
    
    render() { 
        return (  
            <div className="modal fade" id="Modal_FeatureMethods" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">

                        <TitleLine data="Des méthodes simples mais efficaces" />
                        <div className="row no-gutters">
                            <div className="col-md-3">
                                <img src={ImgFeatureMethods} alt="..." className="card-img origo-img-fluid" />
                            </div>
                            <div className="col-md-9">
                                <div className="card-body origo-detail-text-m">
                                    <p>Notre démarche consiste à mobiliser nos clients le moins possible, quelle que soit la taille des projets. Encore une fois, nous sommes là pour apporter des solutions, pas des contraintes. Ainsi, chacun peut se concentrer sur ce qui est le plus important pour lui : les besoins de son activité pour notre client, le travail de développement des outils pour nous.</p>
                                    <p>Bien entendu, cette démarche ne néglige pas pour autant la clarté et la transparence durant toute la durée du projet : nous écoutons, nous proposons, nous expliquons ou rassurons si besoin, nous nous adaptons, et nous livrons un produit fini, fiable, correspondant pleinement aux attentes de notre client.</p>
                                    <p>Nous proposons donc un mode de gestion des projets qui va pleinement dans ce sens.</p>
                                </div>
                            </div>
                        </div>

                        <div className="row no-gutters">
                            <div className="col-md-12">
                                <div className="card-body origo-detail-text-m">
                                    <br/>
                                <p>Quelles sont les étapes d'un projet de développement informatique ?</p>

                                <table class="table table-bordered">
                                    <thead>
                                    <tr className="">
                                        <th className="t-center">Notre Client</th>
                                        <th className="t-center">Origo</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr className="">
                                        <td className="t-data">Présentation du projet par l'entreprise qui nous contacte, description du métier, de l'environnement de travail, des besoins en terme d'outils informatique</td>
                                        <td className="t-data">Présentation de nos méthodes de travail, nos premières impressions</td>
                                    </tr>
                                    <tr className="">
                                        <td className="t-center" colspan="2">Echanges, questions, explications</td>
                                    </tr>
                                    <tr className="">
                                        <td className="t-data"></td>
                                        <td className="t-data">Acceptation du dossier par Origo, élaboration et présentation d'un devis, discussions</td>
                                    </tr>
                                    <tr className="">
                                        <td className="t-center" colspan="2">Echanges</td>
                                    </tr>
                                    <tr className="">
                                        <td className="t-center" colspan="2">Début du projet</td>
                                    </tr>
                                    <tr className="">
                                        <td className="t-data"></td>
                                        <td className="t-data">Elaboration des spécifications qui décrivent le projet en détails</td>
                                    </tr>
                                    <tr className="">
                                        <td className="t-center" colspan="2">Présentation au client, discussion, modifications/corrections si nécessaire</td>
                                    </tr>
                                    <tr className="">
                                        <td className="t-data"></td>
                                        <td className="t-data">Elaboration de preuves de concepts techniques si nécessaire : il s'agit de démontrer, par des développements très rapides, que pour une idée non encore concrêtisée (nouveaux process ou intégration de nouveaux matériels par exemple), les éventuels points incertains du projet ne poseront pas de problème</td>
                                    </tr>
                                    <tr className="">
                                        <td className="t-data"></td>
                                        <td className="t-data">Développement de la solution, présentations de maquettes</td>
                                    </tr>
                                    <tr className="">
                                        <td className="t-center" colspan="2">Points rapides réguliers, compte-rendus et réunions à la demande du client</td>
                                    </tr>
                                    <tr className="">
                                        <td className="t-data"></td>
                                        <td className="t-data">Fin du développement, tests internes</td>
                                    </tr>
                                    <tr className="">
                                        <td className="t-center" colspan="2">Présentation d'un prototype dans le cas d'un développement complexe</td>
                                    </tr>
                                    <tr className="">
                                        <td className="t-center" colspan="2">Présentation de la solution à valider, adaptations éventuelles</td>
                                    </tr>
                                    <tr className="">
                                        <td className="t-center" colspan="2">Tests complets en situation réelle</td>
                                    </tr>
                                    <tr className="">
                                        <td className="t-center" colspan="2">Validation et cloture du projet</td>
                                    </tr>
                                    <tr className="">
                                        <td className="t-center" colspan="2">Apéritif ?</td>
                                    </tr>
                                    </tbody>
                                </table>
                                <p>A chaque phase, et principalement au début du projet, tout peut-être mis sur la table. Pas de non-dits, y compris sur les points pouvant être délicats. Le but étant de n'avoir aucune (mauvaise) surprise à la fin du projet.</p>
                                <p>Par ailleurs, nous sommes favorables à l'utilisation d'outils de visioconférences pour nos échanges, surtout au regard de la situation sanitaire actuelle.</p>
                                <button type="button" className="btn btn-more btn-more-theme btn-sm" data-toggle="modal"  data-target="#Modal_Communication"> 
                                    En savoir plus
                                </button>
                                </div>
                            </div>
                        </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary origo-btn-dialog" data-dismiss="modal">Retour</button>
                    </div>
                    </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default ModalFeatureMethods;