import React, { Component } from "react";
//import Modal from "react-bootstrap/Modal"; 
import TitleLine from "../components/TitleLine"
import ImgMultiApps from './../img/omnichannel.png';

class ModalMultiApps extends Component {
    
    /*
    state = { shown : false };
    
    Show() {
        this.setState({shown : true})
    }

    Hide() {
        this.setState({shown : false})
    }

    componentDidMount() {
        this.setState({shown : true})
    }
    */
    
    render() { 
        return (  
            <div className="modal fade" id="Modal_MultiApps" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">

                        <TitleLine data="Des systèmes multi-plateformes..." />
                        <div className="row no-gutters">
                            <div className="col-md-2">
                                <img src={ImgMultiApps} alt="Des petites applications aux systèmes multi-plateformes" className="card-img origo-img-fluid" />
                            </div>
                            <div className="col-md-10">
                                <div className="card-body origo-detail-text-xl">
                                    <p>Quand les intervenants et les lieux d'exploitations sont nombreux et variés, les plateformes se multiplient : PC/Mac, téléphone mobile, tablettes, terminaux dédiés… beaucoup de dispositifs à alimenter en données.</p>
                                </div>
                            </div>
                        </div>

                        <div className="row no-gutters">
                            <div className="col-md-12">
                                <div className="card-body origo-detail-text-m">
                                        <p>Tous peuvent coexister dans un même système car chacun a ses spécificités :</p>
                                            <ul>
                                                <li>le PC fixe servant de station de travail dans un bureau, ou de pilotage pour des périphériques ou des machines</li>
                                                <li>le téléphone, qui est devenu un ordinateur portable à part entière dans de nombreux environnements professionnels. Il peut héberger des applications très polyvalentes, assurant la majorité des besoins métiers en situation nomade. Ainsi, sa mobilité et ses possibilités en matière de photos, vidéos, son et communication sont en passe d'en faire le premier dispositif utilisé en milieu professionnel</li>
                                                <li>la tablette avec son grand écran tactile, idéale pour disposer de grandes quantité d'informations tout en restant mobile</li>
                                                <li>les terminaux dédiés, embarquant des matériels spécifiques comme des lecteurs de code-barre, RFID, caméra</li>
                                                <li>des objets connectés, à la fonction et au design spécifique à votre métier. Ils peuvent vous fournir des informations essentielles à votre activité en temps réel, vous présenter des données-clés de manière immédiate et synthétique, contenir des capteurs de toute sorte et communiquer leur données au reste de vos applications métier de façon totalement autonome</li>
                                            </ul>
                                        
                                        <p>Nous pouvons intervenir dans tous types d'applications :</p>
                                            <ul>
                                                <li>gestions d'articles, de stocks, de mouvements, de commandes</li>
                                                <li>gestion d'agenda, de rendez-vous, d'interventions, de flottes</li>
                                                <li>gestion de documents, classements, indexations, recherches, créations, éditions, échanges</li>
                                                <li>gestion de formulaires, acquisition de données</li>
                                                <li>traitement de données, synthèses, rapports, alertes</li>
                                                <li>exploitation de bases de données, alimentations, requêtages, mises à jour</li>
                                                <li>calculs, simulations</li>
                                                <li>pilotage de matériels et périphériques bureautiques ou industriels</li>
                                                <li>détection et traçabilité d'objets ou de produits, suivi de parcours</li>
                                            </ul>
                                        
                                        <p>Nous créons les outils correspondant à vos spécifications, en terme de fonctionnalités, de taille et de coût de revient.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary origo-btn-dialog" data-dismiss="modal">Retour</button>
                    </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default ModalMultiApps;