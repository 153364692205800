import React, {Component} from "react";
import SeparatorSection from './SeparatorSection';

export class Footer extends Component {

    render() {
        return (
            <div id="footer">
              <SeparatorSection size={2} color={"#ff9000"}/>
              <div className="container text-center">
              <p>&copy; 2020 Origo</p>
              <p><a href="/legal">Mentions légales</a></p>
              </div>
          </div>
        )
    }
}

export default Footer;