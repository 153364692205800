import React, { Component } from "react";



class ModalEmpty extends Component {
    
    state = { shown : false };
    
    Show() {
        this.setState({shown : true})
    }

    Hide() {
        this.setState({shown : false})
    }

    render() { 
        return (  
            <div className="modal fade" id="Modal_Empty" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Informations</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                    Cette information est en cours mise à jour. Merci de réessayer un peu plus tard. Veuillez nous excuser.
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary origo-btn-dialog" data-dismiss="modal">Close</button>
                    </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default ModalEmpty;