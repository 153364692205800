import React, { Component } from "react";
import TitleLine from "../components/TitleLine"
import ImgModal from './../img/Outils_comm_02.png';

class ModalCommunication extends Component {
    
    render() { 
        return (  
            <div className="modal fade" id="Modal_Communication" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">

                        <TitleLine data="Communication, Rendez-vous, Echanges, Situation sanitaire" />
                        <div className="row no-gutters">
                            <div className="col-md-4">
                                <img src={ImgModal} alt="..." className="card-img origo-img-fluid" />
                            </div>
                            <div className="col-md-8">
                                <div className="card-body origo-detail-text-xl">
                                        <p><span className="origo-text-light-color">Origo</span> respecte scrupuleusement toutes les mesures de sécurité préconisées. Lorsque nous sommes amenés à nous déplacer chez nos interlocuteurs et clients, là encore nous respectons toutes les procédures qui y sont en vigueur.</p>
                                        <p>Par ailleurs, parce-que les conditions sanitaires actuelles ne permettent plus d'envisager les échanges de la même manière, ou tout simplement parce-qu'il est parfois difficile de dégager le temps nécessaire à un rendez-vous à l'extérieur, surtout s'il est improvisé, nous tirons partie des <span className="origo-hlight-word">outils de communication contemporains</span>, notamment pour les <span className="origo-hlight-word">visio-conférences</span>.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-md-12">
                                <div className="card-body origo-detail-text-xl">
                                        <p>Skype, Zoom, Teams, Hangouts, Whereby, LiveStorm, Jitsi…, il n'y a que le choix des plateformes (et si vous souhaitez mettre en place une telle solution, nous pouvons vous conseiller ou vous assister).</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary origo-btn-dialog" data-dismiss="modal">Retour</button>
                    </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default ModalCommunication;