import React, { Component } from 'react'
import { BrowserRouter, Route } from "react-router-dom";
import Data from '../data/data.json';
import Navigation from './Navigation';
import PgMain from '../pages/PgMain';
//import PgServices from '../pages/PgServices';
//import PgServicesApplications from '../pages/PgServicesApplications';
//import PgServicesUpdates from '../pages/PgServicesUpdates';
//import PgServicesWebSites from '../pages/PgServicesWebSites';
//import PgTechnos from '../pages/PgTechnos';
import PgLegal from '../pages/PgLegal';


export class App extends Component {
  state = {
    landingPageData: {}
  }
  getlandingPageData() {
    this.setState({landingPageData : Data})
  }

  componentDidMount() {
    this.getlandingPageData();
  }

  render() {
    return (
      <div>
        <BrowserRouter>
          <Navigation data={this.state.landingPageData.Navigation} />
          <div id="appContent">
          <Route exact path="/" component={PgMain} />
          {/*<Route path="/services" component={PgServices} />
          <Route path="/servicesLittleApps" component={PgServicesApplications} />
          <Route path="/servicesClassicApps" component={PgServicesApplications} />
          <Route path="/servicesUpdt" component={PgServicesUpdates} />
          <Route path="/servicesWebsites" component={PgServicesWebSites} />
          <Route path="/features" component={PgTechnos} />*/}
          <Route path="/legal" component={PgLegal} />
          </div>
        </BrowserRouter>
      </div>
    )
  }
}

export default App;
