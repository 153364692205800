import React, { Component } from "react";
//import ModalCommunication from '../modals/ModalCommunication';

export class SectionHeader extends Component {
  render() {
    return (
      <header id="sectionHeader">
        <div className="intro">
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="col-md-10 col-md-offset-1 intro-text">
                  <h6>
                  {/*<div className="origo-header-title">{this.props.data ? this.props.data.title.map((d) => <p>{d}</p>) : "loading"}</div>*/}
                      <div className="origo-header-title">
                        <p>"Aucun logiciel existant pour <span className="origo_highlight">notre métier</span> n'est adapté à notre manière de l'exercer..."</p>
                        <p>"...Ils sont <span className="origo_highlight">trop complexes</span>, ou <span className="origo_highlight">trop généralistes..."</span></p>
                        <p>"...Et en créer n'est pas à notre portée, ni dans nos moyens..."</p>
                      </div>
                    <div className="origo-text-light-color">{this.props.data ? this.props.data.title_response : "Loading"}</div>
                  </h6>
                  <span></span>
                    <p>
                    {this.props.data ? this.props.data.paragraph.map((d, i) => <div key={`${d.name}-${i}`} >{d}</div>) : "loading"}
                    </p>
                    <a href="/#sectionServices" className="btn btn-custom btn-lg page-scroll">
                    Découvrez nos services...
                  </a>
                  <br/><br/>
                  {/*<ModalCommunication />*/}
                  <button type="button" className="btn btn-more btn-sm" data-toggle="modal"  data-target="#Modal_Communication">
                    Covid19 - Situation sanitaire
                  </button>
                  {" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default SectionHeader;
