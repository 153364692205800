import React, { Component } from "react";
import TitleLine from "../components/TitleLine"
import ImgLittleApps from './../img/vecteurlogiciel2.png';

class ModalLittleApps extends Component {
    
    render() { 
        return (  
            <div className="modal fade" id="Modal_LittleApps" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">

                        <TitleLine data="Des petites applications..." />
                        <div className="row no-gutters">
                            <div className="col-md-2">
                                <img src={ImgLittleApps} alt="Des petites applications aux systèmes multi-plateformes" className="card-img origo-img-fluid" />
                            </div>
                            <div className="col-md-10">
                                <div className="card-body origo-detail-text-xl">
                                        <p>Comme dans tout métier, un outil ne doit pas être jaugé à sa taille mais à la fonction qu'il remplit. Qu'on les appelle petites applications, utilitaires, voire "moulinettes", ces logiciels, souvent dédiés à une seule tâche, se révèlent indispensables, et constituent une part considérable des applications réellement utiles en entreprise.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-md-12">
                                <div className="card-body origo-detail-text-m">
                                    <p>Si certains pensent qu'il ne s'agit que de "bidouille" et leur préfèrent des logiciels beaucoup plus complexes, ces applications ont, quand elles sont bien conçues, des avantages considérables liés à leur apparente simplicité :</p>
                                        <ul>
                                            <li>elles sont <span className="origo-hlight-word">faciles à utiliser</span>, et souvent automatisables</li>
                                            <li>elles sont <span className="origo-hlight-word">fiables</span>, ils fonctionnent tout le temps, et font ce pourquoi ils sont conçus</li>
                                            <li>elles ont un <span className="origo-hlight-word">rapport prix/prestation</span> imbattable</li>
                                        </ul>
                                    <p>...et comme souvent, choisir la simplicité est souvent plus efficace et beaucoup moins onéreux.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary origo-btn-dialog" data-dismiss="modal">Retour</button>
                    </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default ModalLittleApps;