import React, { Component } from 'react'
import Header from '../components/SectionHeader';
import Services from '../components/SectionServices';
import Relationship from '../components/SectionRelationship';
import Features from '../components/SectionFeatures';
import Contact from '../components/SectionContact';
import Footer from '../components/Footer';
import JsonData from '../data/data.json';
import JsonDataService from '../data/data_service.json';
import JsonDataAbout from '../data/data_about.json';

export class PgMain extends Component {
  state = {
    landingPageData: {},
    serviceData: {},
    aboutData: {}
  }
  getlandingPageData() {
    this.setState({landingPageData: JsonData, serviceData: JsonDataService, aboutData: JsonDataAbout})
  }

  componentDidMount() {
    this.getlandingPageData();
  }

  render() {
    return (
      <div>
          <Header data={this.state.landingPageData.Header} />
          <Services data={this.state.serviceData.Services} />
          <Relationship data={this.state.aboutData.About} />
          <Features data={this.state.landingPageData.Features} />
          <Contact data={this.state.landingPageData.Contact} />
          <Footer />
      </div>
    )
  }
}

export default PgMain;
