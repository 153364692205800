import React, { Component } from "react";
import TitleLine from "../components/TitleLine"
import ImgModal from './../img/Technos_diagonales_05.png';

class ModalFeatureTechnos extends Component {
    
    render() { 
        return (  
            <div className="modal fade" id="Modal_FeatureTechnos" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">

                        <TitleLine data="Des technologies contemporaines et pertinentes" />
                        <div className="row no-gutters">
                            <div className="col-md-3">
                                <img src={ImgModal} alt="..." className="card-img origo-img-fluid" />
                            </div>
                            <div className="col-md-9">
                                <div className="card-body origo-detail-text-xl">
                                        <p>Forts de nos expériences, nous utilisons donc les outils les plus appropriés à la solution à développer, et aux objectifs à atteindre, afin de produire des logiciels fiables, performants, maintenables et de la plus grande longévité.</p>
                                        <p>Les applications que nous produisons répondent à des caractéristiques communes : robustesse, fonctionnalités repondant exactement aux besoins définis, navigation et cheminements courts et logiques. La pertinence et le bons-sens priment sur les modes.</p>
                                </div>
                            </div>
                        </div>
                        <br/>
                        <div className="row no-gutters">
                            <div className="col-md-12">
                                <div className="card-body origo-detail-text-m">
                                    <p>Nous couvrons les besoins sur les plateformes actuelles (PC, téléphones, tablettes, terminaux spécifiques) à base de systèmes Windows, Linux et Android. De ce fait, les outils que nous utilisons se doivent d'être cohérents non seulement sur l'ensemble de ces systèmes, mais aussi sur les différentes plateformes.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary origo-btn-dialog" data-dismiss="modal">Retour</button>
                    </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default ModalFeatureTechnos;