import React, { Component } from "react";
//import ModalFeatureRelationship from '../modals/ModalFeatureRelationship';
import ModalFeatureMethods from '../modals/ModalFeatureMethods';
import ModalFeatureTeam from '../modals/ModalFeatureTeam';
import ModalFeatureTechnos from '../modals/ModalFeatureTechnos';
import ImgSkills from './../img/Mosaique_11.png';
import ImgTools from './../img/Technos_diagonales_04.png';
import ImgMethods from './../img/Scrum.png';
//import ImgClient from './../img/Groupe_03.png';
import ModalCommunication from '../modals/ModalCommunication';

export class SectionFeatures extends Component {

render() {
    return (
      <div id="sectionFeatures" className="text-center">
        <div className="container">
          <div className="col-md-10 col-md-offset-1 section-title">
            <h2>Pourquoi nous choisir ?</h2>
          </div>
          <div className="row">

          <div className="card col-xs-8 col-md-4">
              <img src={ImgMethods} className="card-img-top origo-card-img" alt="..." />
              <div className="card-body">
                <h5 className="card-title">Des méthodes simples mais efficaces</h5>
                <p className="card-text">Dans l'élaboration de vos outils, nous vous proposons un cadre rendant notre collaboration simple, efficace, vous mobilisant le moins de temps possible...</p>
                <button type="button" className="btn btn-more btn-more-theme btn-sm" data-toggle="modal"  data-target="#Modal_FeatureMethods"> 
                  En savoir plus
                </button>
                <ModalFeatureMethods />
              </div>
            </div>
          
            <div className="card col-xs-8 col-md-4">
              <img src={ImgSkills} className="card-img-top origo-card-img" alt="..." />
              <div className="card-body">
                <h5 className="card-title">Une équipe expérimentée</h5>
                <p className="card-text">Nous avons la chance de pourvoir compter sur nos expériences issues de secteurs variés, et un niveau de technicité nous permettant de répondre à tous les besoins...</p>
                <button type="button" className="btn btn-more btn-more-theme btn-sm" data-toggle="modal"  data-target="#Modal_FeatureTeam"> 
                  En savoir plus
                </button>
                <ModalFeatureTeam />
              </div>
            </div>
          
            <div className="card col-xs-8 col-md-4">
              <img src={ImgTools} className="card-img-top origo-card-img" alt="..." />
              <div className="card-body">
                <h5 className="card-title">Des technologies contemporaines et pertinentes</h5>
                <p className="card-text">Le choix des outils et technologies actuelles permettant de réaliser des logiciels fiables, performants, agréables et efficaces pour l'utilisateurs...</p>
                <button type="button" className="btn btn-more btn-more-theme btn-sm" data-toggle="modal"  data-target="#Modal_FeatureTechnos"> 
                  En savoir plus
                </button>
                <ModalFeatureTechnos />
              </div>
            </div>
            <ModalCommunication />
          </div>
        </div>
      </div>
    );
  }
}

export default SectionFeatures;
