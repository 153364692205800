import React, { Component } from "react";
import TitleLine from "../components/TitleLine";
//import ImgUpdates from './../img/ART_11_18.png';
import ImgUpdates from './../img/HistoryH.jpg';

class ModalUpdtApps extends Component {
    
    render() { 
        return (  
            <div className="modal fade" id="Modal_UpdtApps" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">

                            <TitleLine data="Evolutions, nouvelles fonctionnalités pour vos outils" />
                            <div className="row no-gutters">
                                <div className="col-md-4">
                                    <img src={ImgUpdates} alt="" className="card-img origo-img-fluid" />
                                </div>
                                <div className="col-md-8">
                                    <div className="card-body">
                                        <div className="origo-detail-text-xl">
                                            <p>Quand votre métier ou vos besoins évoluent, vos outils demandent souvent à être adaptés ou complétés. La décision de faire évoluer ses applications avec de nouvelles fonctionnalités ou des modifications de celles déjà présentes peut avoir de nombreuses causes.</p>
                                        </div>
                                </div>
                            </div>
                            </div>
                            <div className="row no-gutters">
                                <div className="col-md-12">
                                    <div className="card-body origo-detail-text-m">
                                        <br/>
                                        <p>Les principales que l'on va retrouver sont :</p>
                                        <ul>
                                            <li>la mise en évidence de fonctionnalités non prévues au départ, mais devenues indispensables avec le recul</li>
                                            <li>malheureusement des erreurs de fonctionnement de votre logiciel qui n'avaient pas été détectées auparavent ou lors de la livraison</li>
                                            <li>un changement de certains de vos processus métier, de vos procédures ou de vos méthodes. Il est évident que votre outil informatique peut être adapté dans ce sens</li>
                                            <li>l'intégration et l'exploitation de ressources externes comme des webservices, des api ou des bases de données extérieures</li>
                                            <li>de nouvelles obligations légales liées à votre métier ou à la vie de votre entreprise en général : par exemple de nouvelles règles de comptabilité, ou le respect de normes de gestion des données comme la RGPD, …</li>
                                            <li>une informatisation nécessaire des échanges de données avec vos partenaires externes, comme vos fournisseurs ou vos clients. Pour que vos systèmes informatiques communiquent, des modifications sont parfois nécessaires</li>
                                            <li>l'intégration de nouveaux matériels intervenant dans votre process métier. Cela peut être des imprimantes, des machnies de mesure, des scanners, des convoyeurs ou des robots. Votre système peut les piloter et récolter leurs infomations.</li>
                                        </ul>
                                        <p>Pour vos nouveaux besoins, votre outil peut évoluer pour répondre pleinement aux nécessités de vos process métier, et ce à moindre frais.</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary origo-btn-dialog" data-dismiss="modal">Retour</button>
                        </div>
                        </div>
                </div>
            </div>
        );
    }
}
 
export default ModalUpdtApps;